<template>
  <div class="article">
    <div class="container">
      <div class="article-title">
        {{ datas.title }}
      </div>
      <div v-if="datas.source">{{ datas.source }}</div>
      <div class="article-time">
        {{ datas.published_at || datas.created_at }}
      </div>
      <!-- <div v-if="datas['img']" class="article-img">
        <img :src="datas['img']" alt="" />
      </div> -->
      <div class="article-content" v-html="datas.content"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { articleView } from '@/api/app.js'
import { useRoute } from 'vue-router'
const datas = ref([])
const route = useRoute()
const init = (id) => {
  articleView({ id }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      datas.value = data
    }
  })
}
watch(
  route,
  (e) => {
    const { id } = e.query || {}
    if (id) {
      init(id)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.container {
  padding-top: 30px;
  padding-bottom: 30px;
}
.article {
  &-title {
    font-size: 30px;
    // text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: title-font, 'Arial';
  }
  &-time {
    color: #999;
    margin-bottom: 10px;
  }
  &-img {
    font-size: 0;
    margin: 0 auto;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
  &-content {
    line-height: 1.5;
    :deep(img) {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
}
</style>
